.startup-projects-main {
  display: flex;

}

.startup-projects-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.starup-project-image > img {
  max-width: 100%;
  height: auto;
}

.project-card-head {
  color: rgb(36, 41, 46);
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0;
}

.startup-project-text {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px;
}

.project-card-description {
  color: rgb(88, 96, 105);
}
.highlight,
.nextu-div {
  cursor: pointer;
}

@media (max-width: 768px) {
  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}

.StartupProjectImage{
    width: 250px;
    height: auto;
    border-radius: 4px;
}

.proj-description{
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  font-family: "Google Sans Regular", serif;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
  padding: 0 1em;
}

.proj-name{
  text-align: center;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular", serif;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0;
}